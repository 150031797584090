import React, { useEffect, useRef, useState } from 'react';

import { Container, Row, Col } from '../components/react-grid';
import Layout from "../components/layout";
import SEO from "../components/seo";
import Pane from '../components/Pane';
import Hero from '../components/Hero';
import Button from '../components/Button';
import OverlayBlock from '../components/OverlayBlock';
import Slideshow from '../components/Slideshow';
import SlideshowImage from '../components/SlideshowImage';
import Pagination from '../components/Pagination';

import css from './community.module.scss';
import slideshowImage01 from '../images/community-slideshow-01.jpg';
import slideshowImage02 from '../images/community-slideshow-02.jpg';
import slideshowImage03 from '../images/community-slideshow-03.jpg';
import ambassadorsImage from '../images/community-ambassadors-photo.jpg';

import youngLeadersBoardData from '../../content/young-leaders-board.yaml';

const YoungLeadersBoard = ({ data = [] }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [marginLeft, setMarginLeft] = useState(0);
  const [total, setTotal] = useState(0);

  const ypslideRef = useRef('ypslide');

  // ensure that the data set is split in half, the higher half will be on top
  // obviously
  const perRow = Math.ceil(data.length/2);

  const handlePaginate = i => {
    const { scrollWidth, parentElement } = ypslideRef.current;
    const { width } = parentElement.getBoundingClientRect();

    let destx = (width * i) * -1;
    const diff = scrollWidth + destx;

    // if the diff is larger than the width, just move the amount of the diff
    // this way the slide, at the end is flush with the right margin
    if (diff < width) {
      destx = ((width * (i - 1)) + diff) * -1;
    }

    setMarginLeft(destx);
    setActiveIndex(i);
  };

  const resizeWindowHandler = () => {
    const { innerWidth } = window;

    // the number of columns per row will change as the breakpoints change
    // tablet will be 2 per and mobile with be 1, anything other will be fixed
    // at 3
    let n = Math.ceil(perRow/3);
    if (innerWidth <= 768) {
      n = Math.ceil(perRow/2);
    }
    if (innerWidth <= 425) {
      n = data.length;
    }

    setTotal(n);
    handlePaginate(0); // reset the pagination on resize
  };

  useEffect(() => {
    resizeWindowHandler(); // initial trigger

    window.addEventListener('resize', resizeWindowHandler);
  
    return () => {
      window.removeEventListener('resize', resizeWindowHandler);
    };
  }, [data]);

  return (
    <>
      <div className={css.youngLeadersBoardGroup}>
        <div style={{height: '100%', width: '100%', position: 'relative'}}>
          <div
            ref={ypslideRef}
            className={css.youngLeadersContainer}
            style={{ left: marginLeft }}
          >
            {data.map(({ item }, i) => {
              const photoPath = require(`../images/${item.photo}`);
              return (
                <>
                  {i === perRow && (<div className={css.lineBreak}></div>)}
                  <div className={css.youngLeader} key={`yl-${i}`}>
                    <div className={css.youngLeader__desc}>
                      <p 
                        dangerouslySetInnerHTML={{ __html: item.description}}
                      ></p>
                    </div>
                    <div
                      className={css.youngLeader__photo}
                      style={{
                        backgroundImage: `url(${photoPath})`
                      }}
                    ></div>
                  </div>
                </>
              );
            })}
          </div>
        </div>

        {data.length > 6 && (
          <div className={css.pagination}>
            <Pagination 
              total={total} 
              onPageHandler={handlePaginate} 
              active_index={activeIndex}
            >
            </Pagination>
          </div>
        )}
      </div>
    </>
  );
}

const Community = () => {
  return (
    <Layout name="community">
      <SEO title="Community" keywords={[`WAYE`, `WAYE Talks`]} />

      <Pane name="landing" heighted navOffset className={css.landing}>
        <OverlayBlock
          className={css.landing__overlayBlock}
          modifier="community"
          action={true}
          action_url="/contact-us"
          action_text="Join The WAYE Community">
          Changing the narrative of who should be talking tech, and who should be
          listening.
        </OverlayBlock>
        <Slideshow name="community">
          <SlideshowImage src={slideshowImage03} />
          <SlideshowImage src={slideshowImage02} className={css.slideshowImage2} />
          <SlideshowImage src={slideshowImage01} className={css.slideshowImage1} />
        </Slideshow>
      </Pane>

      <Pane name="insert">
        <Container className={css.insertTextGroup}>
          <Row>
            <Col xs="10" xs-offset="1" sm="8" sm-offset="2" className={css.insertText}>
              <p>
                WAYE’s community is reflective of the need for more diverse
                voices in tech, featuring some of the most influential names in
                fashion, technology, and entrepreneurship - ranging from world
                renowned supermodels to Forbes 30 Under 30 winners to the
                youngest editors and thought-leaders at Condé Nast.
              </p>
            </Col>
          </Row>
        </Container>
      </Pane>

      <Pane name="community-become-an-ambassador" heighted className={css.becomeAnAmbassadorGroup}>
        <div className={css.photo} style={{backgroundImage: `url(${ambassadorsImage})`}}></div>
        <div className={css.bodyTextGroup}>
          <Container className={css.bodyText}>
            <Row>
              <Col xs="10" xs-offset="1" sm="6" sm-offset="2" className={css.bodyText__text}>
                <p>
                  A network of the foremost respected innovators in business and
                  technology, working in tandem with WAYE.
                </p>
              </Col>
              <Col xs="10" xs-offset="1" sm="3" sm-offset="0" className={css.bodyText__action}>
                <Button to="/contact-us">Become A Specialist</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </Pane>

      <Pane 
        name="community-become-a-young-leader"
        className={css.becomeAYoungLeader}
      >
        <YoungLeadersBoard
          data={youngLeadersBoardData.content}
        />

        <div className={css.bodyTextGroup}>
          <Container className={css.bodyText}>
            <Row className={css.bodyText__row}>
              <Col xs="10" xs-offset="1" sm="3" sm-offset="1" className={css.bodyText__action}>
                <Button to="/contact-us">Inquire about the WAYE Young Leaders Board </Button>
              </Col>
              <Col xs="10" xs-offset="1" sm="6" sm-offset="0" className={css.bodyText__text}>
                <p>
                  A group of competitively selected, exceptional young leaders who are 
                  passionate about getting our future with technology right.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </Pane>
    </Layout>
  );
};

/**
 * expose
 */

export default Community;
